<template>
  <div class="landing-page">
    <div>
      <h1>Enterprise Resource Planning System for Regenerative Agriculture</h1>
      <p>
        GranjaEU is a cloud-based ERP system for regenerative agriculture. It helps farmers manage their
        operations, track their inventory, and monitor their financials.
      </p>
      <p>
        GranjaEU will launch soon. Join the waitlist to try the beta before it's publicly available.
      </p>
      <div v-if="!confirmation" class="enter-email">
        <v-text-field v-model="email" label="Email" placeholder="Enter your email" outlined dense :rules="emailRules"
          required></v-text-field>
        <v-btn @click="submit" :disabled="!email" color="primary" class="white--text">Join Waitlist</v-btn>
      </div>
      <div v-if="confirmation" class="confirmation">
        <p>Thank you for joining the waitlist. We'll be in touch soon!</p>
      </div>
    </div>
    <div>
      <img src="@/assets/images/sheep.jpg" />
    </div>
  </div>
</template>

<style>
.confirmation {
  color: green;
  font-weight: bold;
}

.landing-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 2rem;
}

.landing-page H1 {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 400px) {
  .landing-page H1 {
    font-size: 16pt;
  }
}

.landing-page IMG {
  width: 400px;
}

@media only screen and (max-width: 400px) {
  .landing-page {
    flex-direction: column;
    flex-flow: column-reverse;
    gap: 1rem;
  }

  .landing-page DIV {
    width: 100%;
  }

  .landing-page IMG {
    width: 300px;
  }

}

.enter-email {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  width: 30rem;
}

@media only screen and (max-width: 400px) {
  .enter-email {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 0rem;
  }
}
</style>

<script>
export default {
  name: 'LandingPage',
  data: () => ({
    confirmation: false,
    email: '',
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) => /.+@.+\..+/.test(v) || 'Email must be valid'
    ]
  }),

  methods: {
    submit () {
      fetch('/api/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: this.email })
      })
        .then((response) => {
          if (response.ok) {
            this.confirmation = true
          } else {
            throw new Error('Something went wrong')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}
</script>
